'use client';

import { useEffect, useState } from 'react';

export function SiteFooter(): React.JSX.Element | null {
  const [os, setOs] = useState<'android' | 'ios' | 'other'>('other');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setOs('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('ios');
    } else {
      setOs('other');
    }
  }, []);

  if (os === 'other') {
    return null; // Do not display the footer for non-iOS and non-Android devices
  }

  return (
    <>
      <div className="h-[50px]"></div>
      <div className="bg-background/95 supports-[backdrop-filter]:bg-background/60 text-foreground fixed bottom-0 left-0 z-50 flex h-[50px] w-full items-center justify-center border-t p-3 text-center backdrop-blur">
        {os === 'android' && (
          <a
            href="https://play.google.com/store/apps/details?id=org.musiclocal.app"
            className="text-primary flex items-center justify-center underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              className="mr-1 h-4 w-4 fill-current"
            >
              <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6" />
            </svg>
            Get Android App
          </a>
        )}
        {os === 'ios' && (
          <a
            href="https://apps.apple.com/us/app/musiclocal/id6470649809"
            className="text-primary flex items-center justify-center underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              className="mr-1 h-4 w-4 fill-current"
            >
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            Get iOS App
          </a>
        )}
      </div>
    </>
  );
}
